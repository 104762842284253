/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye_closed': {
    width: 26,
    height: 18,
    viewBox: '0 0 26 18',
    data: '<g _fill="none" fill-rule="evenodd" _stroke="#154F4A" stroke-width="1.5"><path pid="0" stroke-linecap="square" d="M1 4.9c3.55 3.307 7.55 4.96 12 4.96s8.45-1.653 12-4.96"/><path pid="1" d="M13.3 11.8V16m4.2-4.657l1.087 4.057m-9.6-4.057L7.9 15.4"/></g>'
  }
})
